<template>
  <div style="padding-top:14px">
    <h1>{{ title }}</h1>
  </div>
</template>

<script>
export default {
  data: () => ({
    title: "Ini halaman Berita",
  }),
  watch: {},
  computed: {},
  created() {},
  mounted(){},
  methods: {},
}
</script>